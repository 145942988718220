import { Backdrop, Box, Button, Grid, SxProps, Typography, createTheme } from "@mui/material"
import lightThemeOptions from "../../theme/lightThemeOptions"
import LoadingCircles from "../LoadingCircles"
const lightTheme = createTheme(lightThemeOptions)
type Props = {
    open: boolean
    onOk: () => Promise<void> | void
    loading?: boolean
}
function DontCloseModal({ open, onOk, loading }: Props) {
    // const [loading, setLoading] = useState(false);
    return (
        <>
            <Backdrop
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 0,
                    alignItems: "flex-start",
                }}
                open={open}
            >
                <Box sx={classes.containerBox}>
                    <Grid container sx={classes.containerGrid}>
                        <Grid item xs={12}>
                            <Typography sx={classes.modalTitle}>Payment Processing</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    fontWeight: "medium",
                                    fontSize: "14px",
                                    textAlign: "center",
                                }}
                            >
                                Please do not close or refresh this page. You will be redirected to the payment gateway
                                page now.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                sx={classes.finalPayment}
                                onClick={async () => {
                                    await onOk()
                                }}
                                disabled={loading}
                            >
                                {loading ? <LoadingCircles /> : "Ok"}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Backdrop>
        </>
    )
}
const classes = {
    containerBox: {
        boxSizing: "border-box",
        position: "relative",
        top: "50%",
        width: "80%",
        transform: "translateY(-50%)",
        background: "white",
        padding: {
            xs: "1.5rem",
            sm: "1rem",
            md: "1.5rem",
        },
        borderRadius: "10px",
        maxWidth: "500px",
    },
    containerGrid: {
        height: "100%",
        gap: "1rem",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        justifyItems: "center",
    },
    modalTitle: {
        color: lightTheme.palette.secondary.main,
        fontWeight: "bold",
        fontSize: "15px",
        textAlign: "center",
    },
    finalPayment: {
        backgroundColor: "black",
        textTransform: "unset",
        fontSize: "15px",
        fontWeight: "bold",
        borderRadius: "32px",
        color: "white",
        mb: "5px",
        py: "10px",
        "&:hover": {
            backgroundColor: "black",
        },
        "&:disabled": {
            backgroundColor: "gray",
            color: "#3a3a3a",
        },
    } as SxProps,
}
export default DontCloseModal
