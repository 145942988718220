import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  createTheme,
  Box,
  Link,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setConceptOptions,
  setPDF,
  setPaymentInfo,
  setRegistered,
  setToken,
} from "../../store/ducks/app";
import { getTableByCode } from "../../services/table";
import Logo from "../../components/Logo";
import { getOrderBySimphonyId } from "../../services/order";
import { setOrder, setPaymentDue } from "../../store/ducks/order";
import { StoreState } from "../../store/ducks";
import { setTable } from "../../store/ducks/table";
import NotFound from "../../components/NotFound";
import landingPageImage from "../../assets/orninaLounge.jpg";
import { TableStatus } from "../../constants/enums";
import S3URL from "../../constants/S3URL";
import { TranslationsEnum } from "../../constants/TranslationsEnum";
import { detectDevice } from "../../helpers/utils";
import {
  soSocialAppStore,
  soSocialGooglePlay,
} from "../../constants/constants";
import { toast } from "react-toastify";
import { Link as RouterLink } from "react-router-dom";
import LottieAnimation from "../../components/LottieAnimation/LottieAnimation";
const lightTheme = createTheme(lightThemeOptions);
const LandingPage: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const { order } = useSelector((state: StoreState) => state.order);
  const conceptOptions = useSelector(
    (state: StoreState) => state.app.conceptOptions
  );
  const [loading, setLoading] = useState(order ? false : true);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("t");
  const table = useSelector((state: StoreState) => state.table.table);
  const [orderLoading, setOrderLoading] = useState(order ? false : true);
  const reduxConcept = useSelector(
    (state: StoreState) => state.app.conceptOptions
  ) || { mainBg: landingPageImage };
  const handlePDF = () => {
    const pdfName = "CDM-Menu-Food-V3.pdf";
    dispatch(setPDF(pdfName));
    navigate("menu", { state: { pdf: pdfName } });
  };
  const [imagesLoaded, setImagesLoaded] = useState(0); // Track loaded images

  const queryParams = new URLSearchParams(window.location.search);
  const info = queryParams.get("info");
  const source = queryParams.get("source");

  const handlePayment = () => {
    if (error === "order") {
      navigate("/noorder");
      console.log("orderError");
      return;
    }
    navigate("payment");
  };

  if (token) {
    dispatch(setToken(token));
    dispatch(setRegistered(true));
  }
  useEffect(() => {
    const fetchTable = async (showLoading?: boolean) => {
      try {
        if (showLoading) {
          setLoading(true);
        }
        if (info) {
          dispatch(setPaymentInfo(info));
          navigate("payment-status" + "?info=" + info + "&source=" + source);
        }
        const table = await getTableByCode(id!);
        if (!table || !table.status) {
          setError("table");
          return;
        }
        if (table.status === TableStatus.OCCUPIED && table.currentOrder) {
          dispatch(setOrder(table.currentOrder));
          // setLoading(false);
        }

        dispatch(setTable(table));

        // Fetch Concept
        const conceptOptions = table.concept;
        conceptOptions.id = table.conceptID;
        conceptOptions.pos_id = "401"; // Need Change!
        dispatch(setConceptOptions(conceptOptions));
      } catch (error) {
        console.log(error);
        setError("Something went wrong. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    if (order) {
      fetchTable(false);
    } else {
      fetchTable(true);
    }
  }, []);
  useEffect(() => {
    // Fetch Simphony Order
    const fetchOrder = async () => {
      if (!table || order) return;
      try {
        setOrderLoading(true);
        const simphonyId = table.simphonyID;
        const order = await getOrderBySimphonyId({
          simphonyId,
          conceptId: table.conceptID,
        });

        if (!order || !order.MenuItems) {
          setError("order");
          return;
        }
        // order.DiscountItems = [
        //   {
        //     SalesCount: 1,
        //     RevCtrID: "1253",
        //     mTaxCollectedTotal: -1.4742,
        //     totalAmount: -12.004199999999999,
        //     DetailLink: "5",
        //     DetailType: "DtlDscType",
        //     obj: "2175",
        //     price: -12.004199999999999,
        //     Total: -10.53,
        //     paidQTY: 0,
        //     pcnt: 0,
        //     Name: "Anyware Dis",
        //     __typename: "DiscountItem",
        //   },
        //   {
        //     SalesCount: 1,
        //     RevCtrID: "1253",
        //     mTaxCollectedTotal: -1.4742,
        //     totalAmount: -12.004199999999999,
        //     DetailLink: "6",
        //     DetailType: "DtlDscType",
        //     obj: "150",
        //     price: -12.004199999999999,
        //     Total: -10.53,
        //     paidQTY: 0,
        //     pcnt: 0,
        //     Name: "Another Dis",
        //     __typename: "DiscountItem",
        //   },
        // ];
        dispatch(setOrder(order));
        dispatch(setPaymentDue(order?.Due!));

        // Navigate to payment status screen
        // handlePayment();
        if (token && order) {
          handlePayment();
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again.");
      } finally {
        setOrderLoading(false);
      }
    };
    fetchOrder();
  }, [table]);
  useEffect(() => {
    // Set a timeout to update the state after 5 seconds (5000 milliseconds)
    const timeoutId = setTimeout(() => {
      setImagesLoaded(3);
      console.log("fired");
    }, 8000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);
  const handleImageLoad = () => {
    console.log("img");
    setImagesLoaded((prev) => prev + 1);
  };
  const handleNavigateSosocial = () => {
    if (detectDevice(navigator.userAgent) === "iOS") {
      window.open(soSocialAppStore, "_blank", "noopener,noreferrer");
    } else {
      window.open(soSocialGooglePlay, "_blank", "noopener,noreferrer");
    }
  };

  if (!loading && error && error !== "order") {
    if (error === "table") {
      return <NotFound type={error} />;
    }
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Typography
          variant="h4"
          sx={{
            color: lightTheme.palette.primary.main,
          }}
        >
          {error}
        </Typography>
      </Grid>
    );
  }
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      {
        (loading || imagesLoaded < (conceptOptions?.EnableLoyalty ? 3 : 2)) && (
          // (conceptOptions?.EnableLoyalty ?
          // <SplashScreen /> :
          <LottieAnimation />
        )
        // )
      }
      <Grid
        container
        style={{
          height: "100%",
        }}
      >
        <Box
          component={"img"}
          src={`${S3URL}${reduxConcept.mainBg}`}
          sx={{
            top: 0,
            left: 0,
            width: "100%",
            height: "51.5%",
            objectFit: "cover",
            objectPosition: "center",
            zIndex: -1,
          }}
          onLoad={handleImageLoad}
        ></Box>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "75px 75px 0px 0px",
            marginTop: "-50%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              width: "130px",
              height: "130px",
              borderRadius: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-30px",
              position: "absolute",
              top: "-50px",
              filter: "drop-shadow(0px 3px 6px #00000029)",
              overflow: "hidden",
            }}
          >
            <Logo
              color="primary"
              sx={{
                cursor: "pointer",
                width: "100%",
              }}
              onLoad={handleImageLoad}
            />
          </Box>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            // rowSpacing={4}
            sx={{ marginTop: "50px" }}
          >
            {/* Buttons and Language Switcher */}
            <Grid
              container
              direction="column"
              style={{ width: "80%" }}
              rowSpacing={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: "20px",
                gap: "15px",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  color: "#403F3F",
                  borderRadius: "30px",
                  backgroundColor: "#F4F2F2",
                  fontSize: "14px",
                  fontWeight: "bold",
                  py: "15px",
                  width: "80%",
                  boxShadow: "unset",
                  textTransform: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handlePayment}
                disabled={orderLoading}
              >
                {t("PayNow")}
                {orderLoading && (
                  <Box
                    sx={{
                      ml: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}
                {order && order !== null && order.remainingAmount ? (
                  <>
                    <Typography
                      component={"span"}
                      fontWeight={"bold"}
                      fontSize={"14px"}
                    >
                      <Typography
                        component={"span"}
                        fontSize={14}
                        fontWeight={"medium"}
                      >
                        &nbsp;&bull;&nbsp;AED&nbsp;
                      </Typography>
                      {order.remainingAmount.toFixed(2) || ""}
                      {/* {conceptOptions?.serviceFee
                        ? calcWithServiceFees({
                            total: order.remainingAmount,
                            serviceFee: conceptOptions?.serviceFee,
                          }).total.toFixed(2)
                        : order.remainingAmount.toFixed(2) || ""} */}
                    </Typography>
                  </>
                ) : (
                  ""
                )}
              </Button>

              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: "#403F3F",
                  borderRadius: "30px",
                  backgroundColor: "#F4F2F2",
                  fontSize: "14px",
                  fontWeight: "bold",
                  py: "15px",
                  width: "80%",
                  boxShadow: "unset",
                  textTransform: "none",
                }}
                onClick={handlePDF}
              >
                {t("ViewMenu")}
              </Button>
            </Grid>

            <Grid
              item
              sx={{
                width: "90%",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: conceptOptions?.EnableLoyalty ? "40px" : "100px",
              }}
            >
              {conceptOptions?.EnableLoyalty && (
                <>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "medium",
                      color: "#606060",
                      mb: "5px",
                    }}
                  >
                    {t(TranslationsEnum.earnCashbackLanding)}
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "#F4F2F2",
                      width: "70%",
                      borderRadius: "30px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        width: "40%",
                      }}
                      component="img"
                      alt="logo"
                      src={
                        "https://qrsolutionbucket130835-production.s3.us-east-2.amazonaws.com/public/sosocialminified-1721585757726.webp"
                      }
                      onClick={handleNavigateSosocial}
                      onLoad={handleImageLoad}
                    />
                  </Box>
                </>
              )}
            </Grid>
            {/* Footer */}
            <Grid item sx={{ width: "90%", textAlign: "center", mt: "90px" }}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "medium",
                  color: "#606060",
                }}
              >
                Powered By SoSocial{" "}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "medium",
                  color: "#606060",
                }}
              >
                By using you accept our{" "}
                <Link
                  component={RouterLink}
                  to={"/terms"}
                  sx={{ color: "#606060", textDecoration: "underline" }}
                >
                  terms and conditions
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default LandingPage;
