import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Typography,
  Collapse,
  Box,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
  InputBase,
} from "@mui/material";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import { orderedItems, orderedItems2 } from "../../constants/JsonObjects";
import { TranslationsEnum } from "../../constants/TranslationsEnum";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";
import { ServiceFeeInput } from "../../models/API";
import { MoneyTypes } from "../../constants/enums";

const calcWithServiceFees = ({
  total,
  serviceFee,
}: {
  total: number;
  serviceFee: ServiceFeeInput;
}) => {
  if (serviceFee.type === MoneyTypes.PERCENTAGE) {
    const newTotal = total + total * (Number(serviceFee.amount) / 100);
    const serviceFees = total * (Number(serviceFee.amount) / 100);

    return {
      total: newTotal,
      serviceFee: serviceFees,
    };
  } else {
    return {
      total: total + Number(serviceFee.amount),
      serviceFee: Number(serviceFee.amount),
    };
  }
};
interface OrderedItemsProps {
  withSelection?: boolean;
  TotalSetter?: any;
}

const OrderedItems: React.FC<OrderedItemsProps> = ({
  withSelection = false,
  TotalSetter,
}) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState("-1");
  const [enterQtyOpened, setEnterQtyOpened] = React.useState("-1");
  const [error, setError] = useState({ qty: false });
  const [qty, setQty] = React.useState("1");
  const { serviceFee } = useSelector(
    (state: StoreState) => state.app.conceptOptions
  )!;

  const handleQtyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    Number(event.target.value) > 0 && setQty(event.target.value);
  };

  // Mock ordered items list
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [partiallySelectedItemIds, setPartiallySelectedItemIds] = useState<
    string[]
  >([]);
  const [partiallySelectedItems, setPartiallySelectedItems] = useState<any>([]);

  const calculateTotalSelectiveAmount = (array: any) => {
    const total = orderedItems.reduce(
      (total, item) =>
        array.includes(item.id) ? total + item.qty * item.price : total,
      0
    );
    const PartiallySelectedTotal = partiallySelectedItems.reduce(
      (total: any, item: any) =>
        partiallySelectedItemIds.includes(item.id)
          ? total + item.qty * item.price
          : total,
      0
    );

    return total + PartiallySelectedTotal;
  };
  const calculateTotalAmount = () => {
    const total = orderedItems.reduce(
      (total, item) => total + item.qty * item.price,
      0
    );

    return total;
  };

  const handleCheckboxChange = (itemId: string) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedItems, itemId];
      }
    });
  };
  useEffect(() => {
    if (TotalSetter) {
      const total = withSelection
        ? calculateTotalSelectiveAmount(selectedItems)
        : calculateTotalAmount();
      TotalSetter(total);
    }
  }, [selectedItems]);
  const getName = (data: string) => {
    const array = JSON.parse(data);
    const item = array.find((item: any) => item.language === i18n.language);

    return item.name;
  };
  const handleConfirmQTY = (item: any) => {
    const Item = orderedItems.find((order) => order.id === item);

    if (Item) {
      var Quantity = qty;
      if (parseInt(qty) > Item.qty - Item.paidQty) {
        setQty((prev) => Item.qty - Item.paidQty + "");
        Quantity = Item.qty - Item.paidQty + "";
      }
      const newObj = {
        price: Item.price,
        qty: parseInt(Quantity),
        id: Item.id + "partial",
      };

      if (!partiallySelectedItemIds.includes(newObj.id)) {
        setPartiallySelectedItemIds((prev: any) => [...prev, newObj.id]);
        setPartiallySelectedItems((prev: any) => [...prev, newObj]);
      } else {
        const existingItem = partiallySelectedItems.find(
          (selectedItem: any) => selectedItem.id === newObj.id
        );

        if (existingItem) {
          // Update the existing item
          const updatedItems = partiallySelectedItems.map(
            (selectedItem: any) => {
              if (selectedItem.id === existingItem.id) {
                return { ...selectedItem, qty: parseInt(Quantity) };
              } else {
                return selectedItem;
              }
            }
          );
          setPartiallySelectedItems(updatedItems);
        }
      }
    }
  };
  return (
    <>
      <TableContainer>
        <Table
          size={withSelection ? "small" : "medium"}
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow style={{ background: "#f3f4f6" }}>
              {withSelection && (
                <TableCell colSpan={1}>
                  {/* <Typography variant="h6">{t("Select")}</Typography> */}
                </TableCell>
              )}
              <TableCell>
                <Typography variant="h6">{t("Item")}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">{t("Quantity")}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">{t("Price")}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!withSelection &&
              orderedItems2.map((item, i) => (
                <>
                  <TableRow
                    key={item.Name}
                    onClick={() => {
                      // withSelection &&
                      //   (!item.paid && item.qty === 1
                      //     ? handleCheckboxChange(item.id)
                      //     : open === item.id
                      //     ? setOpen("-1")
                      //     : setOpen(item.id));
                    }}
                    sx={{ cursor: withSelection ? "pointer" : "default" }}
                  >
                    {withSelection && (
                      // (item.qty === 1 && !item.paid?
                      <TableCell
                        sx={{
                          borderWidth: orderedItems.length - 1 === i ? 2 : 1,
                        }}
                      >
                        {!item.paid ? (
                          <Checkbox
                            color={item.paid ? "success" : "primary"}
                            // checked={selectedItems.includes(item.id) || item.paid}
                          />
                        ) : (
                          <CheckIcon
                            color="success"
                            style={{ marginLeft: "0.5rem" }}
                          />
                        )}
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        borderWidth: orderedItems.length - 1 === i ? 2 : 1,
                      }}
                      style={{
                        color: withSelection && item.paid ? "green" : "#000",
                      }}
                    >
                      {item.Name}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderWidth: orderedItems.length - 1 === i ? 2 : 1,
                      }}
                      style={{
                        color: withSelection && item.paid ? "green" : "#000",
                      }}
                    >
                      x{item.qty}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderWidth: orderedItems.length - 1 === i ? 2 : 1,
                      }}
                      style={{
                        color: withSelection && item.paid ? "green" : "#000",
                      }}
                    >
                      {`${item.Total}`}
                    </TableCell>
                  </TableRow>
                </>
              ))}
            {withSelection &&
              orderedItems.map((item, i) => (
                <>
                  <TableRow
                    key={item.id}
                    onClick={() => {
                      withSelection &&
                        (!item.paid && item.qty === 1
                          ? handleCheckboxChange(item.id)
                          : open === item.id
                          ? setOpen("-1")
                          : setOpen(item.id));
                    }}
                    sx={{ cursor: withSelection ? "pointer" : "default" }}
                  >
                    {
                      withSelection && (
                        // (item.qty === 1 && !item.paid?
                        <TableCell
                          sx={{
                            borderWidth: orderedItems.length - 1 === i ? 2 : 1,
                          }}
                        >
                          {!item.paid ? (
                            <Checkbox
                              color={item.paid ? "success" : "primary"}
                              checked={
                                selectedItems.includes(item.id) || item.paid
                              }
                            />
                          ) : (
                            <CheckIcon
                              color="success"
                              style={{ marginLeft: "0.5rem" }}
                            />
                          )}
                        </TableCell>
                      )
                      // : (
                      //   <TableCell>
                      //     <IconButton
                      //       aria-label="expand row"
                      //       size="small"
                      //       onClick={() =>
                      //         item.id === open ? setOpen("-1") : setOpen(item.id)
                      //       }
                      //     >
                      //       {open === item.id ? (
                      //         <KeyboardArrowUpIcon />
                      //       ) : (
                      //         <KeyboardArrowDownIcon />
                      //       )}
                      //     </IconButton>
                      //   </TableCell>
                      // )
                      // )
                    }
                    <TableCell
                      sx={{
                        borderWidth: orderedItems.length - 1 === i ? 2 : 1,
                      }}
                      style={{
                        color: withSelection && item.paid ? "green" : "#000",
                      }}
                    >
                      {getName(item.multiLanguage)}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderWidth: orderedItems.length - 1 === i ? 2 : 1,
                      }}
                      style={{
                        color: withSelection && item.paid ? "green" : "#000",
                      }}
                    >
                      x{item.qty}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderWidth: orderedItems.length - 1 === i ? 2 : 1,
                      }}
                      style={{
                        color: withSelection && item.paid ? "green" : "#000",
                      }}
                    >
                      {`${item.price} ${t(item.currency)}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ padding: 0 }} colSpan={6}>
                      <Collapse
                        in={open === item.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box sx={{ margin: 1 }}>
                          <Typography
                            variant="h6"
                            gutterBottom
                            component="div"
                            style={{
                              textAlign:
                                i18n.language === "Arabic" ? "right" : "left",
                            }}
                          >
                            {t("History")}
                          </Typography>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                <TableCell>{t("Time")}</TableCell>
                                <TableCell>{t("Customer")}</TableCell>
                                <TableCell align="right">
                                  {t("Amount")}
                                </TableCell>
                                <TableCell align="right">
                                  {t("Total")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item.history.map((historyRow: any) => (
                                <TableRow key={historyRow.date}>
                                  <TableCell component="th" scope="row">
                                    {historyRow.date}
                                  </TableCell>
                                  <TableCell>{historyRow.customerId}</TableCell>
                                  <TableCell align="right">
                                    {historyRow.amount}
                                  </TableCell>
                                  <TableCell align="right">
                                    {Math.round(
                                      historyRow.amount * item.price * 100
                                    ) / 100}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            {!item.paid && (
                              <>
                                <TableRow>
                                  <TableCell
                                    colSpan={4}
                                    align="center"
                                    style={{ color: "red" }}
                                  >
                                    {t("payfor")}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell colSpan={3}>
                                    <InputBase
                                      fullWidth
                                      type="number"
                                      size="medium"
                                      value={qty}
                                      placeholder={t("EnterAmount")}
                                      onChange={handleQtyChange}
                                      inputProps={{ min: 1, max: item.qty }} // Adding minimum value
                                      style={{
                                        textAlign: "center",
                                        border: "1px solid #888",
                                        borderRadius: 10,
                                        fontSize: 16,
                                        fontWeight: 500,
                                        color: "#888",
                                        paddingLeft:
                                          i18n.language === "Arabic"
                                            ? "1rem"
                                            : "1rem",
                                        paddingRight:
                                          i18n.language === "Arabic"
                                            ? "1rem"
                                            : "0rem",
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        height: "32px",
                                        borderRadius: "10px",
                                      }}
                                      onClick={() => handleConfirmQTY(item.id)}
                                    >
                                      {t("Confirm")}
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </>
                            )}
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            {/* Extra row for total */}
            <TableRow sx={{ width: "100%" }}>
              {/* {withSelection && <TableCell />} */}
              <TableCell colSpan={2}>
                <Typography
                  sx={{
                    textAlign: i18n.language === "Arabic" ? "right" : "left",
                  }}
                  // variant="h6"
                  fontWeight="bold"
                >
                  {t(TranslationsEnum.serviceFee)}
                </Typography>
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{
                  height: "10px",
                }}
              >
                <Typography
                  // variant="h6"
                  sx={{ textAlign: "right", fontWeight: "bold" }}
                >
                  {withSelection
                    ? calcWithServiceFees({
                        total: calculateTotalSelectiveAmount(selectedItems),
                        serviceFee,
                      }).serviceFee.toFixed(2)
                    : calcWithServiceFees({
                        total: calculateTotalAmount(),
                        serviceFee,
                      }).serviceFee.toFixed(2)}{" "}
                  {t("AED")}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow sx={{ width: "100%" }}>
              {/* {withSelection && <TableCell />} */}
              <TableCell colSpan={2}>
                <Typography
                  sx={{
                    textAlign: i18n.language === "Arabic" ? "right" : "left",
                  }}
                  // variant="h6"
                  fontWeight="bold"
                >
                  {t("Total")}
                </Typography>
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{
                  height: "10px",
                }}
              >
                <Typography
                  sx={{ textAlign: "right", fontWeight: "bold" }}
                >
                  {withSelection
                    ? calcWithServiceFees({
                        total: calculateTotalSelectiveAmount(selectedItems),
                        serviceFee,
                      }).total.toFixed(2)
                    : calcWithServiceFees({
                        total: calculateTotalAmount(),
                        serviceFee,
                      }).total.toFixed(2)}{" "}
                  {t("AED")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {/* Customer Name Modal */}
      <Dialog
        open={enterQtyOpened !== "-1"}
        onClose={() => setEnterQtyOpened("-1")}
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <DialogContent>
          <IconButton
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
            color="error"
            onClick={() => setEnterQtyOpened("-1")}
          >
            <ClearIcon />
          </IconButton>

          <Typography
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            {t("EnterQty")}
          </Typography>

          <DialogContentText
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              size="small"
              value={qty}
              placeholder={t("EnterQtyPlaceholder")}
              onChange={handleQtyChange}
              style={{ marginBottom: "10px" }}
            />

            <Button
              variant="contained"
              color="primary"
              style={{
                height: "36px",
                borderRadius: "10px",
              }}
              onClick={() => {
                handleConfirmQTY(enterQtyOpened);
              }}
            >
              {t("Confirm")}
            </Button>
            {error.qty && (
              <Typography
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  color: "red",
                }}
              >
                {t("enterQtyPrompt")}
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OrderedItems;
